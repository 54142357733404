import 'mdn-polyfills/Element.prototype.classList';
import 'nodelist-foreach-polyfill';
import PiximCookies from './modules/cookies.js';
import SlideToggle from './modules/slidetoggle.js';

document.addEventListener('DOMContentLoaded', function() {

    //Module pour les cookies
    let chooseCookies = document.querySelector('.accept-cookie');
    //SlideToggle
    let slideToggleElement = document.querySelectorAll('.choisir');
    new SlideToggle(slideToggleElement);
    window.PiximCookies = PiximCookies;

    if(chooseCookies) {

        let form = chooseCookies.querySelector('form');
        let lifetimeCookie = chooseCookies.dataset.cookieLifetime;

        if(chooseCookies.dataset.init === '1') {
            setTimeout(function() {
                chooseCookies.classList.add('active');
            }, 400);
        }

        //Boutons d'action
        let cookie_button_refus = chooseCookies.querySelector('.refus');
        let cookie_button_choisir = chooseCookies.querySelector('.choisir');
        let cookie_button_tous = chooseCookies.querySelector('.tous');

        //Options préférences
        let cookie_button_choisir_texte = cookie_button_choisir.textContent;
        let cookie_button_choisir_ready = null;

        //J'accepte tout
        cookie_button_tous.addEventListener('click', function() {
            acceptAll(form, lifetimeCookie);
            PiximCookies.prototype.setCookie('accept_cookie', 'ok', lifetimeCookie);
            closePanel(chooseCookies);
        });

        //Mes préférences
        cookie_button_choisir.addEventListener('click', function() {

            cookie_button_choisir_ready = cookie_button_choisir_ready === null ? true : null;

            if(cookie_button_choisir_ready) { //Premier clic
                this.textContent = 'Valider';
            }
            else { //Deuxième clic : cookie plus fermeture popup
                choixCookies(form, lifetimeCookie);
                PiximCookies.prototype.setCookie('accept_cookie', 'ok', lifetimeCookie);
                // closePanel(chooseCookies);
                this.textContent = cookie_button_choisir_texte;
            }
        });

        //Je refuse
        cookie_button_refus.addEventListener('click', function() {
            refuseAll(form);
            PiximCookies.prototype.setCookie('accept_cookie', 'ok');
            closePanel(chooseCookies);
        });

    }

    let button = document.querySelector('.accept-cookie-infos');
    if(button) {
        button.addEventListener('click', function() {
            chooseCookies.classList.toggle('active');
        });
    }
});

function acceptAll(form, expire = false) {

    for (var i = 0; i < form.elements.length; i++) {
        if(form.elements[i].disabled)
            continue;
        form.elements[i].checked = true;
        PiximCookies.prototype.setCookie(form.elements[i].name, form.elements[i].value, form.elements[i].dataset.expire || expire);
    }
}

function refuseAll(form) {

    for (var i = 0; i < form.elements.length; i++) {
        if(form.elements[i].disabled) continue;
        //Suppression du cookie
        PiximCookies.prototype.removeCookie(form.elements[i].name);
    }
}

function choixCookies(form, expire = false) {

    for (var i = 0; i < form.elements.length; i++) {

        if(form.elements[i].disabled)
            continue;
        if(form.elements[i].checked) { //Cookie accepté
            PiximCookies.prototype.setCookie(form.elements[i].name, form.elements[i].value, form.elements[i].dataset.expire || expire);
        }
        else { //Suppression du cookie
            PiximCookies.prototype.removeCookie(form.elements[i].name);
        }
    }
}

function closePanel(element) {
    element.classList.remove('active');
}

window.modChooseCookiesGetCookie = function() {
    return PiximCookies.prototype.getCookie(...arguments);
}
