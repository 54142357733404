
    const PiximCookies = function(element, parent, expire) {

        if(!element) return;
        var current_object = this;

        if(typeof element.removeClass == "undefined") { //Ajout de la méthode removeClass à JavaScript

            HTMLElement.prototype.removeClass = function(classe) {
                current_object._removeClass.call(this, classe);
            }
        }

        //Définition du parent
        if(parent) {
            this.parent = parent;
            this.expire = parseInt(this.parent.getAttribute('data-cookie-lifetime'));
        }
        else {
            this.expire = parseInt(expire);
        }

        element.addEventListener('click', function() {
            if(parent)
                current_object.parent.removeClass('active'); //Suppression de la classe active
            current_object.setCookie(current_object.key, 'ok', this.expire);
        });
    };

    PiximCookies.prototype = {

        key: 'pixim_cookie',
        setCookie: function(name, value, expire, path) { //Expire en seconde

            var timestamp = +new Date();
            var params = {
                "name": name,
                "value": value,
                "expire": expire ? (timestamp) + (expire * 1000) : null,
                "path": path ? path : '/'
            };

            var option = encodeURIComponent(params.name) +'='+ encodeURIComponent(params.value);
            if(params.expire) option += '; expires=' + (new Date(params.expire));
            if(params.path) option += '; path=' + params.path;

            document.cookie = option;
        },
        getCookie: function(name, defaut = null) {

            var cookies = document.cookie.split(';');
            for(var i = 0; i < cookies.length; i++) {

                var cook = cookies[i].split('=');

                if(cook[0].replace(' ', '') == name)
                return cook[1];
            }

            return defaut;
        },
        removeCookie: function(name, path) {
            PiximCookies.prototype.setCookie(name, null, -1, path);
        },
        _removeClass: function(classe) {

            var _class = this.className.split(' ');
            var index = _class.indexOf(classe);

            if(index >= 0) { //Classe trouvée

                delete _class[index]; //Suppression de la classe CSS
                this.className = _class.join(' ').trim(); //On reconstruit la classe de l'élément
            }
        }
    }

    export default PiximCookies;
