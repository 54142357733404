    class SlideToggle {

        constructor(elements, duration = 500) {

            if(!elements) return false;
            if(typeof elements.length == 'undefined')
                this.elements = [elements];
            else
                this.elements = elements;
            this.duration = duration;
            this.timers = new Array();
            this.animated = false;
            this.slideToggle = this.slideToggle.bind(this);
            this.slideToggle();
        }

        static slideUp(element, duration = 500, callback = false) {

            //Styles permanents
            element.style.boxSizing = 'border-box';
            element.style.overflow = 'hidden';

            element.style.height = element.offsetHeight+'px';
            //Définition de la transition pour l'animation
            element.style.transition = `height ${duration}ms, padding ${duration}ms, margin ${duration}ms`;
            //Recalcule de la taille : redessine la page
            element.offsetHeight;

            //Redéfinition des styles
            element.style.height = 0;
            element.style.paddingTop = 0;
            element.style.paddingBottom = 0;
            element.style.marginTop = 0;
            element.style.marginBottom = 0;
            element.style.border = 0;

            setTimeout(() => {
                element.style.display = 'none';
                element.style.removeProperty('height');
                element.style.removeProperty('padding');
                element.style.removeProperty('margin');
                element.style.removeProperty('border');
                element.style.removeProperty('box-sizing');
                element.style.removeProperty('overflow');
                //Suppression de la transition pour éviter les bugs
                element.style.removeProperty('transition');

                if(callback)
                    callback();
            }, duration);
        }

        static slideDown(element, duration = 500, callback = false) {

            //Styles permanents
            element.style.boxSizing = 'border-box';
            element.style.overflow = 'hidden';

            //Suppression du display artificiel
            element.style.removeProperty('display');
            let display = window.getComputedStyle(element).display; //Si jamais l'élément était caché d'office (par le CSS)
            if(display === 'none')
                display = 'block';

            //On affiche l'élément
            element.style.display = display;

            //Calcule de la hauteur de l'élément
            let height = element.offsetHeight;
            //On masque l'élément pour voir l'animation
            element.style.height = 0;
            element.style.paddingTop = 0;
            element.style.paddingBottom = 0;
            element.style.marginTop = 0;
            element.style.marginBottom = 0;
            element.style.border = 0;
            //Redessine l'élément pour pouvoir appliquer la transition
            element.offsetHeight;
            //Rebind de la transition pour voir l'animation correctement
            element.style.transition = `height ${duration}ms, padding ${duration}ms, margin ${duration}ms`;
            //Application de la hauteur pour voir l'animation
            element.style.height = height+'px';
            element.style.removeProperty('padding');
            element.style.removeProperty('margin');
            element.style.removeProperty('border');

            setTimeout(() => {
                element.style.removeProperty('height');
                element.style.removeProperty('transition');
                element.style.removeProperty('box-sizing');
                element.style.removeProperty('overflow');

                if(callback)
                    callback();
            }, duration);
        }

        refrech() {
            setTimeout(() => this.animated = false, 300);
        }

        clearTimer() {

            if(this.timers.length) {
                for(let i in this.timers)
                    clearTimeout(this.timers[i]);
            }
            return this.timers = [];
        }

        slideToggle(element = false) {

            if(element) {
                if(typeof element.length == 'undefined')
                    element = [element];
                this.elements = [...element];
            }

            this.elements.forEach((element) => {

                element.addEventListener('click', () => {

                    let target = element.dataset.target ? document.querySelectorAll(element.dataset.target) : [element];
                    if(this.animated && target.length) return false;

                    target.forEach((elem) => {

                        let display = window.getComputedStyle(elem).display;
                        if(display == 'none') {
                            SlideToggle.slideDown(elem, this.duration);
                        }
                        else {
                            SlideToggle.slideUp(elem, this.duration);
                        }
                    });
                });

            });
        }
    }

    export default SlideToggle;
